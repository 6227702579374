const CSRFTOKEN = document.querySelector('meta[name="csrf-token"]').content;

const defaultOptions = {
    credentials: 'same-origin',
    headers: {
        Accept: 'application/json',
        'X-CSRF-Token': CSRFTOKEN,
    },
}

export const getWithCredentials = (url) => fetch(url, defaultOptions);

export const postWithCredentials = (url, data) => fetch(url, {
    ...defaultOptions,
    method: 'POST',
    headers: {
        ...defaultOptions.headers,
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
});

export const deleteWithCredentials = (url) => fetch(url, {
    ...defaultOptions,
    method: 'DELETE',
});

const createHiddenFormField = (name, value) => {
    const element = document.createElement('input')
    element.hidden = true;
    element.name = name;
    element.value = value;
    return element;
}

export const sendPostbackForm = (url, data) => {
    const form = document.createElement('form');
    document.body.appendChild(form);

    form.method = 'POST';
    form.action = url;
    form.appendChild(createHiddenFormField('authenticity_token', CSRFTOKEN));
    Object.entries(data).forEach(([name, value]) => {
        form.appendChild(createHiddenFormField(name, value));
    });
    form.submit();
}


/**
 * Provide localization with key replacement. Inspired by Svelte repl, but
 * adapted so that each component contains its own translations. This mostly
 * makes sense since we only need two (nb and nn), and thus it's easier to have
 * the relevant texts directly in the components that use them
 *
 * @see https://svelte.dev/repl/de39de663ef2445b8fe17b79c500013b?version=3.55.1
 */
import {getWithCredentials} from '~/common/fetchHelpers.js';

function replaceVars(text, vars) {
  try {
    Object.entries(vars).forEach(([k, v]) => {
      const regex = new RegExp(`{{${k}}}`, "g");
      text = text.replace(regex, v);
    });
  } catch (e) {
    console.error(e);
  }
  return text;
}

/**
 * @param {Record<string, Record<string, string>>} translations
 * @param {string?} locale
 * @returns {function(string, Record<string, string>): string}
 */
export default function localizer(translations, locale) {
  const l = locale || globalThis.vesta?.locale || 'nb';
  return (key, vars = {}) => {
    const t = translations[l] || translations['nb'];
    if (!t) {
      console.error(`No valid translations found for ${l} or nb`)
      return key;
    }
    return replaceVars(t[key] || translations['nb'][key], vars) || key;
  }
}

export async function fetchTranslation(key) {
  return getWithCredentials(`/aid/translate?key=${encodeURIComponent((key || '').trim())}`)
    .then(r => {
      if(!r.ok) {
        console.warn('Error fetching translation for key', key);
        return key;
      }
      return r.json();
    })
    .then(j => {
      return j?.translation || key
    })
    .catch(e => {
      console.error('Failed to fetch key', key, e)
      return key;
    });
}
